import { set, get, cloneDeep } from 'lodash-es'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'

export const bookingUpdateState = (state, action) => {
  const { shiftID, id, attr, val } = action.payload

  const newState = cloneDeep(state.entityMap[shiftID])
  const newAgencyShifts = newState.agency_shift_bookings.find(item => item.id === id)
  // TODO: The whole underlying logic for (agency and Syft) shifts
  // and bookings should be redone at some point.
  set(newAgencyShifts, attr, val)

  return {
    ...state,
    entityMap: {
      ...state.entityMap,
      [shiftID]: newState,
    },
    hasChanges: true,
  }
}

export const failedBookingState = (state, action) => {
  const {
    payload: {
      message,
      response: { status },
      body,
    },
  } = action
  const error_description = get(body, 'error_description') || get(body, 'debug.message')
  const setKey = entitySetName({ shiftID: action.payload.options.shiftID })

  return {
    ...state,
    isSavingData: false,
    lastMessage: String(message),
    lastStatus: String(status),
    lastBody: {
      error_description: String(error_description),
    },
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        ...state.entitySets[setKey],
        isLoadingData: false,
      },
    },
  }
}

const updateAgencyShiftBookings = (state = {}, agencyShiftBookings = []) =>
  state.agency_shift_bookings.map(item => {
    const newItem = agencyShiftBookings.find(responseItem => responseItem.id === item.id)
    return newItem ? newItem : item
  })

export const successSaveBookingState = (state, action) => {
  const { payload: response, request } = action
  const { shiftId } = request

  return {
    ...state,
    entityMap: {
      ...state.entityMap,
      [shiftId]: {
        ...state.entityMap[shiftId],
        agency_shift_bookings: updateAgencyShiftBookings(state.entityMap[shiftId], response),
      },
    },
    entityDetail: {
      ...state.entityDetail,
      [shiftId]: {
        ...state.entityDetail[shiftId],
        agency_shift_bookings: updateAgencyShiftBookings(state.entityDetail[shiftId], response),
      },
    },
    isSavingData: false,
    isLoadingData: false,
    hasChanges: false,
  }
}
